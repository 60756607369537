<template>
	<main id="container" class="page-container information-history bg-wave4">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="행사연혁" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="info-history-header">
					<div class="page-sub-header">
						<h3 class="page-sub-title">지난 동행축제의 발자취입니다. <span class="prima">보고 싶은 회차를 선택하세요.</span></h3>
					</div>
					<div class="info-history-header__select dropdown-style">
						<DropDown ref="dropDown1" id="dropDown1" init="0" placeholder="전체">
							<div class="dropdown-item" v-for="(item, idx) in brdInfoList" :key="idx">
								<button type="button" @click="clickDropdown1($event)" class="text-link">
									<span class="dropdown-text">{{ item.epVal }}</span>
								</button>
							</div>
						</DropDown>
					</div>
				</div>
				<div class="info-history-explain">
					<span class="info-history-explain__title">2024년 9월 동행축제</span>
					<span class="info-history-explain__title-large">황금녘 동행축제</span>
					<p class="info-history-explain__desc">
						추석 명절에 맞춰 감사의 마음을 전하고 서로 응원하며 경제주체들의 기(氣)를 살리는 캠페인. <br />
						온국민이 참여해 전국 지역 경제에 활력 불어넣기!
					</p>
				</div>
				<div class="info-history-guide">
					<span class="info-history-guide__title">‘온 국민이 힘을 모아 우리 경제 기(氣)살리기 캠페인’</span>
					<div class="info-history-guide__event">
						<div class="event-content">
							<div class="event-content-img">
								<img src="~@/assets/img/main/event_icon01.png" alt="행사기간" />
							</div>
							<div class="event-content-desc">
								<span class="event-content-desc__title">행사 기간</span>
								<div class="event-content-desc__period">
									<span>2024년 08월 30일 부터 ~</span>
									<span>2024년 10월 08일 까지</span>
								</div>
							</div>
						</div>
						<div class="event-content">
							<div class="event-content-img">
								<img src="~@/assets/img/main/event_icon02.png" alt="행사내용" />
							</div>
							<div class="event-content-desc">
								<span class="event-content-desc__title">행사 내용</span>
								<p class="event-content-desc__text">온라인 특별할인전, 라이브커머스, 동행제품 판매전, 지역축제 연계 판매전, 소비촉진 이벤트</p>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="infohistory-image-container">
				<ul class="infohistory-image-list">
					<li class="infohistory-image-list-li" v-for="(item, idx) in festaList" :key="idx">
						<a href="" class="infohistory-image-list-item" v-bind:class="{ video: item.video }">
							<img :src="require(`@/assets/img/main/${item.image}`)" alt="" />
							<div class="video-link">
								<i class="icon">
									<img src="~@/assets/img/information/arrow-icon-white.png" alt="" />
								</i>
							</div>
						</a>
					</li>
				</ul>
			</div>
			<div class="container">
				<div class="info-history-products-title">
					<span>동행제품</span>
				</div>
				<div class="category-items">
					<ul class="category-items-list">
						<li class="category-items-list-li" v-for="(item, idx) in ctgrItems" :key="idx">
							<button type="button" class="category-item">
								<div class="category-item-img">
									<!-- <div class="category-item is-active"> -->
									<i class="icon">
										<img :src="require(`@/assets/img/category/${item.img}`)" alt="" />
									</i>
								</div>
								<span class="item-title">{{ item.dcdVal }}</span>
							</button>
						</li>
					</ul>
				</div>
				<div class="products-list">
					<products-item v-for="(item, idx) in starList" :key="item.manprdId" :id="item.id" :num="idx" :item="item" type="S" parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import PageHeader from '@/components/content/PageHeader';
import ProductsItem from '@/components/content/ProductsItem';
import DropDown from '@/components/common/DropDown';

export default {
	name: 'InformationHistory',
	components: { PageHeader, ProductsItem, DropDown },
	data: () => ({
		brdInfoList: [{ epVal: '2024년 9월 동행축제' }, { epVal: '2024년 3월 동행축제' }, { epVal: '2024년 1월 동행축제' }, { epVal: '2024년 9월 동행축제' }, { epVal: '2024년 3월 동행축제' }, { epVal: '2024년 1월 동행축제' }, { epVal: '2024년 9월 동행축제' }, { epVal: '2024년 3월 동행축제' }, { epVal: '2024년 1월 동행축제' }],
		festaList: [
			{
				image: 'bg_festa1.jpg',
				video: true,
			},
			{
				image: 'bg_festa2.jpg',
				video: false,
			},
			{
				image: 'bg_festa3.jpg',
				video: false,
			},
			{
				image: 'bg_festa4.jpg',
				video: true,
			},
			{
				image: 'bg_festa5.jpg',
				video: false,
			},
		],
		ctgrItems: [
			{
				img: 'list_item01.png',
				dcdVal: '전체',
			},
			{
				img: 'list_item02.png',
				dcdVal: '뷰티',
			},
			{
				img: 'list_item03.png',
				dcdVal: '생활',
			},
			{
				img: 'list_item04.png',
				dcdVal: '식품',
			},
			{
				img: 'list_item05.png',
				dcdVal: '전자기기',
			},
			{
				img: 'list_item06.png',
				dcdVal: '가구·조명',
			},
			{
				img: 'list_item07.png',
				dcdVal: '주방',
			},
			{
				img: 'list_item08.png',
				dcdVal: '패션·잡화',
			},
		],
		starList: [],
	}),
	computed: {},
	watch: {},
	created() {},
	mounted() {},
	methods: {
		clickDropdown1(event) {
			this.$refs.dropDown1.selectDropdownItem(event);
		},
	},
};
</script>
